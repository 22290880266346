import React from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { useState } from "react";

const FaqForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  const onFinish = (values) => {
    setLoader(true);
    const token = cookie.load("token");
    const payload = {
      question: { en: values.question },
      answer: { en: values?.answer },
    };
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    console.log("payload", payload);
    axios
      .post(
        `${process.env.REACT_APP_FRONTED_URL}/faqs?locale=en`,
        payload,
        config
      )
      .then(() => {
        setLoader(false);
        navigate("/faq");
        openNotification("Success", "FAQ created Successfully");
      })
      .catch((error) => {
        setLoader(false);
        openNotification("Error", "");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - ADD FAQs</title>
        <meta name="description" content="4everinlove Add FAQs" />
      </Helmet>
      <div className="header">
        <h3>FAQ</h3>
      </div>
      <div className="form_container">
        <Form
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="question"
            label="Question"
            rules={[{ required: true, message: "Please enter your Question" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="answer"
            label="Answer"
            rules={[{ required: true, message: "Please enter your Answere" }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <div className="form_submit">
              <Button className="back_button" onClick={() => navigate("/faq")}>
                Back
              </Button>
              <Button htmlType="submit"> {loader ? "wait..." : "Save"}</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default FaqForm;
