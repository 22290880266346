import React from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Spin,
  notification,
  Upload,
  Image,
  TimePicker,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
const EventForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [eventData, setEventData] = useState();
  const [loader2, setLoader2] = useState(false);
  const [updatedCoverPhoto, setUpdatedCoverPhoto] = useState("");
  const [deletedImagesIds, setDeletedImagesIds] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);

  // convert image to show
  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      setUpdatedCoverPhoto(reader?.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  //method for API call
  const getEventHandler = (page) => {
    setLoader2(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/events/${params?.id}?locale=en`,
        config
      )
      .then((response) => {
        let duplicateEvent = { ...response?.data?.data };
        duplicateEvent = duplicateEvent?.images?.filter(
          (item) => item?.type !== "cover_photo"
        );
        setEventData({
          ...response?.data?.data,
          images: duplicateEvent,
        });
        setLoader2(false);
      })
      .catch((error) => {
        setLoader2(false);
      });
  };

  //  API for subscription Plan
  useEffect(() => {
    getEventHandler();
    // eslint-disable-next-line
  }, []);

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  // handler for multiple files
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList([...fileList, file]);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file) => {
        if (fileList.some((item) => item.uid === file.uid)) {
          setFileList((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList]
  );

  // handler for single files
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList2((state) => [file]);
          getBase64(file);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file) => {
        if (fileList2.some((item) => item.uid === file.uid)) {
          setFileList2((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList2]
  );

  // delete image handler to remove old images
  const deleteImageHandler = (id) => {
    let duplicate = { ...eventData };
    duplicate = duplicate?.images?.filter((img) => img.id !== id);
    setEventData({ ...eventData, images: duplicate });
    // state to store previous images id to remove from DB
    setDeletedImagesIds([...deletedImagesIds, id]);
  };

  // dateHandler to update the format
  const dateHandler = (selectedDate) => {
    let date = new Date(selectedDate);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate())
    );
  };

  // timeHandler to update the format
  const timeHandler = (selectedDate) => {
    let date = new Date(selectedDate);
    console.log("date-time", date);
    return (
      (date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()) +
      ":" +
      (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()) +
      ":" +
      (date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds())
    );
  };

  // date handler to update the format on basis of selction or on basis of previous date (last time selected when creating event)
  const datePayloadHandler = (values, index) => {
    // if else for date
    if (values?.date !== undefined && values?.date !== null) {
      return dateHandler(values?.date?.$d);
    } else {
      return dateHandler(eventData?.from_date?.split(" ")[0]);
    }
  };

  // time handler to update the format on basis of selction or on basis of previous date (last time selected when creating event)
  const timePayloadHandler = (values, index, previousDate) => {
    // if else for date
    if (values?.time !== undefined && values?.time !== null) {
      return timeHandler(values?.time[index]?.$d);
    } else {
      return timeHandler(previousDate);
    }
  };

  // onFinish handler
  const onFinish = (values) => {
    setLoader(true);
    console.log("values", values);
    let CurrentDate = dateHandler(new Date());
    const token = cookie.load("token");
    // key to save all image
    if (
      values?.date !== undefined &&
      values?.date !== null &&
      dateHandler(values?.date?.$d) < CurrentDate
    ) {
      openNotification(
        "Error",
        "Selected date should be same or it should be greater then current date"
      );
      setLoader(false);
    }
    // add metaverse link
    else if (
      (values?.link === undefined ||
        values?.link === null ||
        values?.link === "") &&
      values?.limit !== undefined &&
      Number(values?.limit) > 0
    ) {
      openNotification("Error", "Please add metaverse link");
      setLoader(false);
    }
    // add metaverse limit
    else if (
      (values?.limit === undefined ||
        values?.limit === null ||
        values?.limit === "" ||
        Number(values?.limit) === 0) &&
      values?.link !== undefined &&
      values?.link !== "" &&
      values?.ticket === undefined &&
      values?.ticket === undefined
    ) {
      openNotification("Error", "Please add metaverse limit");
      setLoader(false);
    } else if (
      values?.ticket !== undefined &&
      Number(values?.ticket) > 0 &&
      values?.limit !== undefined &&
      Number(values?.limit) > 0
    ) {
      openNotification(
        "Error",
        "You have added metaverse Limit so you should remove ticket price. After adding limit. Event should be free"
      );
      setLoader(false);
    } else {
      const fileList_tm = fileList;
      // payload
      let payload = {
        name: {
          en: values?.name !== "" ? values?.name : eventData?.name?.en,
        },
        address: values?.address !== "" ? values?.address : eventData?.address,
        description: {
          en:
            values?.description !== ""
              ? values?.description
              : eventData?.description?.en,
        },
        latitude: "28.596352381107707",
        longitude: "77.1711361506293",
      };
      if (fileList2?.length !== 0) {
        payload = { ...payload, cover_photo: fileList2[0] };
      }
      if (fileList?.length > 0) {
        payload = { ...payload, images: fileList_tm };
      }
      if (deletedImagesIds?.length > 0) {
        payload = { ...payload, images_to_delete: deletedImagesIds };
      }
      if (deletedImagesIds?.length > 0) {
        payload = { ...payload, images_to_delete: deletedImagesIds };
      }
      if (values?.link !== undefined && values?.link !== "") {
        payload = { ...payload, link: values?.link };
      }
      if (values?.limit !== undefined && values?.limit !== "") {
        payload = { ...payload, limit: values?.limit };
      }
      if (
        (values?.date !== undefined && values?.date !== null) ||
        (values?.time !== undefined && values?.time !== null)
      ) {
        payload = {
          ...payload,
          from_date:
            datePayloadHandler(values, 0) +
            " " +
            timePayloadHandler(values, 0, eventData?.from_date),
        };
      }
      if (
        (values?.date !== undefined && values?.date !== null) ||
        (values?.time !== undefined && values?.time !== null)
      ) {
        payload = {
          ...payload,
          to_date:
            datePayloadHandler(values, 1) +
            " " +
            timePayloadHandler(values, 1, eventData?.to_date),
        };
      }
      if (
        (values?.ticket_price !== undefined &&
          values?.ticket_price !== null &&
          values?.ticket_price !== "") ||
        Number(values?.ticket) === 0
      ) {
        payload = {
          ...payload,
          ticket_price: Number(values?.ticket),
        };
      }
      // config
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "multipart/form-data",
        },
      };
      // API call
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/events/${params?.id}?_method=PUT&locale=en`,
          payload,
          config
        )
        .then(() => {
          setLoader(false);
          navigate("/events");
          openNotification("Success", "Event Updated Successfully");
        })
        .catch((error) => {
          setLoader(false);
          openNotification("Error", "");
        });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - EDIT EVENTS</title>
        <meta name="description" content="4everinlove Edit Events" />
      </Helmet>
      <div className="header">
        <h3>Edit Event</h3>
      </div>
      {loader2 ? (
        <div className="event_spin">
          <Spin />
        </div>
      ) : (
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            name: eventData?.name?.en,
            address: eventData?.address,
            ticket: eventData?.ticket_price && Number(eventData?.ticket_price),
            description: eventData?.description?.en,
            latitude: "28.596352381107707",
            longitude: "77.1711361506293",
            images: eventData?.images,
            link: eventData?.link,
            limit: eventData?.limit,
          }}
        >
          <div className="cover_image">
            <Form.Item name="cover_photo" label="Profile Photo">
              <div>
                <div>
                  <Image
                    width={200}
                    height={200}
                    src={
                      fileList2?.length !== 0
                        ? updatedCoverPhoto
                        : eventData?.cover_photo
                    }
                    className="rounded-10"
                  />
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <Upload
                    name="cover_photo"
                    multiple
                    {...uploadProps2}
                    fileList={fileList2}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                </div>
              </div>
            </Form.Item>
          </div>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter your Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ticket"
            label="Ticket Price"
            rules={[
              { required: true, message: "Please enter your Ticket Price" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: "Please enter your Address" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="date" label="Date" className="datepicker-container">
            <DatePicker
              style={{ width: "100%" }}
              placeholder={[eventData?.from_date?.split(" ")[0]]}
              type="number"
            />
          </Form.Item>
          <Form.Item
            name="time"
            label="Time (H : M : S)"
            className="datepicker-container"
          >
            <TimePicker.RangePicker
              style={{ width: "100%" }}
              placeholder={[
                eventData?.from_date?.split(" ")[1],
                eventData?.to_date?.split(" ")[1],
              ]}
            />
          </Form.Item>
          <Form.Item name="link" label="Materverse Link">
            <Select
              options={[
                { value: "", label: "Select option" },
                {
                  value:
                    "https://www.spatial.io/s/4EverinLoveCafe-64987ca989f0ff55679ff352?share=5515277605369612935",
                  label: "4EverinLove Cafe",
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="limit" label="Materverse Limit">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter your Description" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="images" label="Images">
            <Upload name="images" multiple {...uploadProps} fileList={fileList}>
              <Button icon={<UploadOutlined />}>Upload New Images</Button>
            </Upload>
          </Form.Item>
          <div>
            <div className="header">
              <p>Gallery</p>
            </div>
            <div
              className="px-2"
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {eventData?.images?.length > 0 &&
              eventData?.images !== undefined ? (
                eventData?.images?.map((img, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "210px",
                    }}
                  >
                    <div className="py-10">
                      <Image
                        width={200}
                        height={200}
                        src={img?.path}
                        className="rounded-10"
                      />
                    </div>
                    <div
                      style={{
                        padding: "5px 0px",
                      }}
                    >
                      <Button onClick={() => deleteImageHandler(img.id)}>
                        Delete
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Images</div>
              )}
            </div>
          </div>
          <Form.Item>
            <div className="form_submit">
              <Button
                className="back_button"
                onClick={() => navigate("/events")}
              >
                Back
              </Button>
              <Button htmlType="submit">{loader ? "wait..." : "Update"}</Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </React.Fragment>
  );
};
export default EventForm;
