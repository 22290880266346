import React from "react";
import { Helmet } from "react-helmet";
import { useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  notification,
  Upload,
  TimePicker,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
const EventForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  // handler for multiple files
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList([...fileList, file]);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file) => {
        if (fileList.some((item) => item.uid === file.uid)) {
          setFileList((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList]
  );

  // handler for single files
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList2((state) => [file]);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file) => {
        if (fileList2.some((item) => item.uid === file.uid)) {
          setFileList2((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList2]
  );

  // dateHandler to update the format
  const dateHandler = (selectedDate) => {
    let date = new Date(selectedDate);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? `0${date.getDate()}` : date.getDate())
    );
  };

  // timeHandler to update the format
  const timeHandler = (selectedDate) => {
    let date = new Date(selectedDate);
    return (
      (date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()) +
      ":" +
      (date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()) +
      ":" +
      (date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds())
    );
  };

  // onFinish handler
  const onFinish = (values) => {
    console.log("values", values);
    setLoader(true);
    const token = cookie.load("token");
    let CurrentDate = dateHandler(new Date());
    // payload
    const fileList_tm = fileList;
    if (dateHandler(values?.date?.$d) < CurrentDate) {
      openNotification(
        "Error",
        "Selected date should be same or it should be greater then current date"
      );
      setLoader(false);
    }
    // add metaverse link
    else if (
      (values?.link === undefined ||
        values?.link === null ||
        values?.link === "") &&
      values?.limit !== undefined &&
      Number(values?.limit) > 0
    ) {
      openNotification("Error", "Please add metaverse link");
      setLoader(false);
    }
    // add metaverse limit
    else if (
      (values?.limit === undefined ||
        values?.limit === null ||
        values?.limit === "" ||
        Number(values?.limit) === 0) &&
      values?.link !== undefined &&
      values?.link !== ""
    ) {
      openNotification("Error", "Please add metaverse limit");
      setLoader(false);
    } else if (
      values?.ticket !== undefined &&
      Number(values?.ticket) > 0 &&
      values?.limit !== undefined &&
      Number(values?.limit) > 0
    ) {
      openNotification(
        "Error",
        "You have added metaverse Limit so you should remove ticket price. After adding limit. Event should be free"
      );
      setLoader(false);
    }
    // success scenario
    else {
      const payload = {
        name: {
          en: values?.name,
        },
        address: values?.address,
        from_date:
          dateHandler(values?.date?.$d) +
          " " +
          timeHandler(values?.time[0]?.$d),
        to_date:
          dateHandler(values?.date?.$d) +
          " " +
          timeHandler(values?.time[1]?.$d),
        cover_photo: values?.cover_photo?.file,
        ticket_price: values?.ticket,
        description: {
          en: values?.description,
        },
        link: values?.link !== "" ? values?.link : "",
        limit: values?.limit !== "" ? values?.limit : "",
        latitude: "28.596352381107707",
        longitude: "77.1711361506293",
        images: fileList_tm,
      };
      // config
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
          "Content-Type": "multipart/form-data",
        },
      };
      console.log("values payload", payload);
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/events?locale=en`,
          payload,
          config
        )
        .then(() => {
          setLoader(false);
          navigate("/events");
          openNotification("Success", "Event created Successfully");
        })
        .catch((error) => {
          setLoader(false);
          openNotification("Error", "");
        });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - ADD EVENTS</title>
        <meta name="description" content="4everinlove Add Events" />
      </Helmet>
      <div className="header">
        <h3>Events</h3>
      </div>
      <div className="form_container">
        <Form layout="vertical" onFinish={onFinish}>
          <div className="cover_image">
            <Form.Item
              name="cover_photo"
              label="Profile Photo"
              rules={[
                { required: true, message: "Please upload your cover photo" },
              ]}
            >
              <Upload
                name="cover_photo"
                multiple
                {...uploadProps2}
                fileList={fileList2}
              >
                <Button icon={<UploadOutlined />}>Upload Image</Button>
              </Upload>
            </Form.Item>
          </div>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter your Name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="ticket"
            label="Ticket Price"
            rules={[
              { required: true, message: "Please enter your Ticket Price" },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: "Please enter your Address" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Please select your Date" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="time"
            label="Time (H : M : S)"
            rules={[{ required: true, message: "Please select yout Time" }]}
          >
            <TimePicker.RangePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="link" label="Materverse Link">
            <Select
              options={[
                { value: "", label: "Select option" },
                {
                  value:
                    "https://www.spatial.io/s/4EverinLoveCafe-64987ca989f0ff55679ff352?share=5515277605369612935",
                  label: "4EverinLove Cafe",
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="limit" label="Materverse Limit">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter your Description" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="images" label="Images">
            <Upload name="images" multiple {...uploadProps} fileList={fileList}>
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <div className="form_submit">
              <Button
                className="back_button"
                onClick={() => navigate("/events")}
              >
                Back
              </Button>
              <Button htmlType="submit">{loader ? "wait..." : "Save"}</Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default EventForm;
