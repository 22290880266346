import React from "react";
import { Helmet } from "react-helmet";
import {
  Image,
  Empty,
  Pagination,
  Popconfirm,
  message,
  Spin,
  Row,
  Col,
  Card,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import { CSVLink } from "react-csv";
// scroll Top
const useScroll = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return [executeScroll, myRef];
};

const EventTickets = () => {
  const [eventData, setEventData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState("");
  const [allCSVData, setAllCSVData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [executeScroll, myRef] = useScroll();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pageParams = queryParams.get("page");
  const { Meta } = Card;

  //method for API call
  const getEventHandler = (page) => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/events?locale=en&page=${page}`,
        config
      )
      .then((response) => {
        setEventData(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });

    // APL call to get all events without pagination
    axios
      .get(`${process.env.REACT_APP_FRONTED_URL}/all-events?locale=en`, config)
      .then((response) => {
        // console.log("calenderdata", response);
        let data = [];
        response?.data?.data?.forEach((item) => {
          data.push({
            id: item.id,
            name: item.name.en,
            price: Number(item?.ticket_price) < 1 ? "Free" : item?.ticket_price,
            address: item.address,
            description: item?.description?.en,
            date: item?.from_date,
            coverPhoto: item?.cover_photo,
          });
        });
        if (data?.length > 0) {
          setAllCSVData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  API for subscription Plan
  useEffect(() => {
    getEventHandler(pageParams);
    // eslint-disable-next-line
  }, []);

  // confirm handler to delete record
  const confirm = (data) => {
    setSelectedRecord(data?.id);
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    // API call to delete selected record
    axios
      .delete(
        `${process.env.REACT_APP_FRONTED_URL}/events?locale=en&events_ids[0]=${data?.id}`,
        config
      )
      .then(() => {
        // to delete locally from our database
        const remaingRecords = eventData?.data?.filter(
          (item) => item?.id !== data?.id
        );
        setEventData({ ...eventData, data: remaingRecords });
        // success message
        message.success("Record deleted Successfully");
        setSelectedRecord("");
      })
      .catch((error) => {
        if (error?.message === "Request failed with status code 500") {
          message.error("You cannot delete an event that has participants.");
        } else {
          message.error("Error");
        }
        setSelectedRecord("");
      });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - EVENTS</title>
        <meta name="description" content="4everinlove Events Tickets" />
      </Helmet>
      <div ref={myRef}>
        <div className="header4">
          <h3>All Events </h3>
          <div className="events-button">
            <CSVLink data={allCSVData} filename={"all-events.csv"}>
              <button className="cursor-pointer csv-button">
                Download All Events
              </button>
            </CSVLink>
            {/* add event */}
            <Link to="/add-event">
              <button className="cursor-pointer add-event">Add Events</button>
            </Link>
          </div>
        </div>
        {/* Events Details Showing Card */}
        {loader ? (
          <div className="spin_information2">
            <Spin className="spin-center2" />
          </div>
        ) : eventData?.data?.length > 0 ? (
          <div>
            <Row gutter={24} scroll={{ x: 1023 }}>
              {eventData?.data?.map((item, id) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={6} key={id}>
                  <Card
                    hoverable
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      margin: "15px 0px",
                    }}
                    cover={
                      <Image
                        height={320}
                        alt="example"
                        src={item?.cover_photo}
                        className="card-image"
                      />
                    }
                    actions={[
                      <React.Fragment>
                        {item?.is_active === 0 ? (
                          <h4 className="event-nodata">Not Active</h4>
                        ) : (
                          <Link to={`/edit-event/${item?.id}`}>
                            <EditOutlined key="edit" />
                          </Link>
                        )}
                      </React.Fragment>,
                      <Popconfirm
                        title="Delete the event"
                        description="Are you sure to delete this event?"
                        onConfirm={() => confirm(item)}
                        okText="Yes"
                        cancelText="No"
                      >
                        {selectedRecord === item?.id ? (
                          <Spin />
                        ) : (
                          <DeleteOutlined key="delete" />
                        )}
                      </Popconfirm>,
                    ]}
                  >
                    <Meta
                      title={
                        <h4 className="card-name-new">{item?.name?.en}</h4>
                      }
                      description={
                        <p className="card-description">
                          {item?.description?.en}
                        </p>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
            <Pagination
              defaultPageSize={currentPage}
              showSizeChanger={false}
              current={currentPage}
              pageSize={
                eventData?.data !== undefined && eventData?.data?.length !== 0
                  ? eventData?.per_page
                  : 1
              }
              total={eventData?.total}
              onChange={(e) => {
                setCurrentPage(e);
                getEventHandler(e);
                executeScroll();
                navigate(`/events?page=${e}`);
              }}
              className="pagination"
            />
          </div>
        ) : (
          <div className="no-data">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="spin-center2"
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default EventTickets;
