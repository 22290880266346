import React from "react";
import { Helmet } from "react-helmet";
import { Tabs, Spin, List } from "antd";
import { useState, useEffect } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { Link } from "react-router-dom";

const Information = () => {
  const [information, setInformation] = useState({});
  const [loader, setLoader] = useState(true);
  //method for API call
  const getSubscripUserHandler = () => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/website-information?locale=en`,
        config
      )
      .then((response) => {
        setInformation(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  console.log("ddd", information);
  //  API for subscription User
  useEffect(() => {
    getSubscripUserHandler();
  }, []);

  const links = [
    {
      title: "Facebook",
      link: information?.links?.facebook,
    },
    {
      title: "Twitter",
      link: information?.links?.twitter,
    },
    {
      title: "Linkedin",
      link: information?.links?.linkedin,
    },
    {
      title: "Instagram",
      link: information?.links?.instagram,
    },
  ];
  const contact = [
    {
      title: "Address",
      link: information?.address,
    },
    {
      title: "Phone",
      link: information?.phone,
    },
  ];
  //
  const items = [
    {
      key: "1",
      label: `Terms & Conditions`,
      children: loader ? (
        <div className="spin_information2">
          <Spin className="spin-center2"/>
        </div>
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: information?.pages?.terms_and_conditions?.en,
          }}
        />
      ),
    },
    {
      key: "2",
      label: `Privacy Policy`,
      children: (
        <p
          dangerouslySetInnerHTML={{
            __html: information?.pages?.privacy_policy?.en,
          }}
        />
      ),
    },
    {
      key: "3",
      label: `Links`,
      children: (
        <List
          itemLayout="horizontal"
          dataSource={links}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item?.link} />
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "4",
      label: `Contact Details`,
      children: (
        <List
          itemLayout="horizontal"
          dataSource={contact}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={item.title} description={item?.link} />
            </List.Item>
          )}
        />
      ),
    },
  ];
  const onChange = (e) => {
    console.log(e);
  };
  return (
    <React.Fragment>
       <Helmet>
        <title>4EVERINLOVE - INFORMATION</title>
        <meta name="description" content="4everinlove Information" />
      </Helmet>
      <div className="header">
        <h3>All Pages Information</h3>
        <Link to="/edit-information">
          <button className="cursor-pointer">Edit information</button>
        </Link>
      </div>
      {/* table */}
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </React.Fragment>
  );
};
export default Information;
