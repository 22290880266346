import React from "react";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { Badge, Calendar, Modal, Button, Spin } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";

const App = () => {
  // calendar page states
  const [calendarData, setCalendarData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedEventDetail, setSelectedEventDetail] = useState({});
  const Navigate = useNavigate();
  //
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  //method for API call
  const getEventHandler = () => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(`${process.env.REACT_APP_FRONTED_URL}/all-events?locale=en`, config)
      .then((response) => {
        // console.log("calenderdata", response);
        setCalendarData(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect
  useEffect(() => {
    getEventHandler();
    setLoader(false);
  }, []);

  // Date handler
  const getDateHandler = (date) => {
    const dateTime = new Date(date);
    const day =
      dateTime.getDate() +
      " " +
      month[dateTime.getMonth()] +
      " " +
      dateTime.getFullYear();
    return day;
  };

  const getListData = (value) => {
    let listData = [];
    // map loop to match with current date and get events to show in calender
    calendarData?.forEach((item) => {
      if (getDateHandler(value?.$d) === getDateHandler(item?.from_date)) {
        return listData?.push({
          type: "success",
          content: item?.name?.en,
          startTime: getDateHandler(item?.from_date),
          endTime: getDateHandler(item?.to_date),
        });
      }
    });
    return listData || [];
  };

  const showDetail = (value) => {
    const data = calendarData?.find(
      (item) => getDateHandler(value?.$d) === getDateHandler(item?.from_date)
    );
    setSelectedEventDetail(data);
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge
              status={item.type}
              text={item.content}
              style={{ textAlign: "left" }}
            />
          </li>
        ))}
      </ul>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    return info.originNode;
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedEventDetail({});
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedEventDetail({});
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - CALENDAR</title>
        <meta name="description" content="4everinlove Calendar" />
      </Helmet>
      <Modal
        title={
          selectedEventDetail ? selectedEventDetail?.name?.en : "Add New Event"
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedEventDetail && Object.keys(selectedEventDetail)?.length > 0 ? (
          <div className="add_event_calendar">
            {" "}
            <div>
              <p>{selectedEventDetail?.description?.en}</p>
            </div>
            <div>
              {" "}
              <p>Address : {selectedEventDetail?.address}</p>
            </div>
            <div>
              <p>Date : {getDateHandler(selectedEventDetail?.from_date)}</p>
            </div>
            <div>
              <p>Time : {selectedEventDetail?.from_date?.split(" ")[1]}</p>
            </div>
          </div>
        ) : (
          <div className="add_event_calendar">
            <p>No Event exist on this date</p>
          </div>
        )}
        <div className="form_submit">
          <Button onClick={() => Navigate("/add-event")}>Add New Event</Button>
        </div>
      </Modal>
      {/* Calender */}
      <div >
        {loader ? (
          <div className="spin_information2">
            <Spin className="spin-center2"/>
          </div>
        ) : (
          <Calendar
            cellRender={cellRender}
            onPanelChange={(e) => {}}
            onSelect={(e) => {
              showModal();
              showDetail(e);
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default App;
