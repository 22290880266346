import { Routes, Route, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
//  Users
import UserTable from "../component/users/users";
import RequestedUsers from "../component/users/requestedUsers";
import UserDetail from "../component/users/userDetails";
// Dashboard
import Dashboard from "../component/dashboard/dashboard";
// Admin
import AdminTable from "../component/admin/admin";
// Subscriptions
import SubscriptionUser from "../component/subscription/subscribedUser";
import SubscriptionPlan from "../component/subscription/subscriptionPlan";
// payments
import PaymentTable from "../component/payment/payment";
// information
import Information from "../component/information/information";
import EditInformation from "../component/information/editInformation";
// events
import CreateEvents from "../component/event/createEvent";
import Events from "../component/event/events";
import EditEvent from "../component/event/editEvent";
import EventTickets from "../component/event/eventTickets";
// faq
import CreateFaq from "../component/faq/createFaq";
import Faq from "../component/faq/faq";
// calendar
import Calendar from "../component/calender/calender";
// contact
import Contact from "../component/contact/contact";
import EditContact from "../component/contact/editContact";
// Report
import Reports from "../component/reports/reports";
// login
import Login from "../component/login/login";
// cookie
import cookie from "react-cookies";
// Layout
import Layout from "../shared/layout";

const AllRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  // useEffect for loggedIn user to check
  useEffect(() => {
    let token = cookie.load("token");
    if (token !== undefined) {
      // navigate(-1);
      setLoggedIn(true);
    } else {
      navigate("/login");
      setLoggedIn(false);
    }
    // eslint-disable-next-line
  }, [cookie.load("token")]);

  return (
    <>
      {/* login page */}
      {!loggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            {/* dashboard */}
            <Route path="/" element={<Dashboard />} />
            {/* Admin */}
            <Route path="/admins" element={<AdminTable />} />
            {/* Users */}
            <Route path="/users" element={<UserTable />} />
            <Route path="/users-requests" element={<RequestedUsers />} />
            <Route path="/user-detail/:id" element={<UserDetail />} />

            {/* Subscriptions */}
            <Route path="/subscribed-users" element={<SubscriptionUser />} />
            <Route path="/subscriptions" element={<SubscriptionPlan />} />
            {/* payments */}
            <Route path="/payments" element={<PaymentTable />} />
            {/* events */}
            <Route path="/events" element={<Events />} />
            <Route path="/edit-event/:id" element={<EditEvent />} />
            <Route path="/add-event" element={<CreateEvents />} />
            <Route path="/event-tickets" element={<EventTickets />} />
            {/* faq */}
            <Route path="/faq" element={<Faq />} />
            <Route path="/add-faq" element={<CreateFaq />} />
            {/* information */}
            <Route path="/information" element={<Information />} />
            <Route path="/edit-information" element={<EditInformation />} />
            {/* calendar */}
            <Route path="/calendar" element={<Calendar />} />
            {/* contact */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/edit-contact-status/:id" element={<EditContact />} />
            {/* reports */}
            <Route path="/reports" element={<Reports />} />
          </Routes>
        </Layout>
      )}
    </>
  );
};
export default AllRoutes;
