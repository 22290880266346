import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Modal, Form, Input, notification } from "antd";
import cookie from "react-cookies";
import axios from "axios";

const EditModal = ({
  isModalOpen,
  editData,
  handleCancel,
  handleOk,
  setAllFaq,
  allFaq,
}) => {
  const [loader, setLoader] = useState(false);
  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  // onFinish handler
  const onFinish = (values) => {
    setLoader(true);
    const token = cookie.load("token");
    const payload = {
      question: { en: values.question },
      answer: { en: values?.answer },
    };
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    axios
      .post(
        `${process.env.REACT_APP_FRONTED_URL}/faqs/${editData?.id}?_method=PUT&locale=en`,
        payload,
        config
      )
      .then(() => {
        setLoader(false);
        // to update locally when record is submitted
        let updatedRecord = [...allFaq];
        updatedRecord[editData?.selectedIndex] = {
          ...updatedRecord[editData?.selectedIndex],
          question: { en: values.question },
          answer: { en: values?.answer },
        };
        setAllFaq(updatedRecord);
        openNotification("Success", "FAQ Updated Successfully");
        handleOk();
      })
      .catch((error) => {
        setLoader(false);
        openNotification("Error", "");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - FAQs</title>
        <meta name="description" content="4everinlove Edit FAQS" />
      </Helmet>
      <Modal
        title="EDIT FAQ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="form_container">
          <Form
            layout="vertical"
            initialValues={{
              question: editData?.question?.en,
              answer: editData?.answer?.en,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="question"
              label="Question"
              rules={[
                { required: true, message: "Please enter your Question" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="answer"
              label="Answer"
              rules={[{ required: true, message: "Please enter your Answer" }]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item>
              <div className="form_submit">
                <Button htmlType="submit">
                  {" "}
                  {loader ? "wait..." : "Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default EditModal;
