import React from "react";
import { Helmet } from "react-helmet";
import { Space, Table } from "antd";
import { useEffect, useState } from "react";
import cookie from "react-cookies";
import axios from "axios";

const SubscriptionPlan = () => {
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [loader, setLoader] = useState(true);

  //method for API call
  const getSubscribHandler = () => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };

    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/subscription-plans?locale=en`,
        config
      )
      .then((response) => {
        setSubscriptionPlan([
          {
            ...response?.data?.data[0],
            package1: `${
              Number(response?.data?.data[0]?.duration) > 1 ? "Days:" : "Day:"
            } ${response?.data?.data[0]?.duration} , Price: ${Number(
              response?.data?.data[0]?.price
            )}`,
            package2: `${
              Number(response?.data?.data[1]?.duration) > 1 ? "Days:" : "Day:"
            } ${response?.data?.data[1]?.duration} , Price: ${Number(
              response?.data?.data[1]?.price
            )}`,
            package3: `${
              Number(response?.data?.data[2]?.duration) > 1 ? "Days:" : "Day:"
            } ${response?.data?.data[2]?.duration} , Price: ${Number(
              response?.data?.data[2]?.price
            )}`,
          },
        ]);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  //  API for subscription Plan
  useEffect(() => {
    getSubscribHandler();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "name",
      render: (data) => <Space size="middle">{data?.name?.en}</Space>,
    },
    {
      title: "Description",
      dataIndex: "",
      key: "description",
      render: (data) => <Space size="middle">{data?.description?.en}</Space>,
    },
    {
      title: "Package 1",
      dataIndex: "package1",
      key: "package1",
    },
    {
      title: "Package 2",
      dataIndex: "package2",
      key: "package2",
    },
    {
      title: "Package 3",
      dataIndex: "package3",
      key: "package3",
    },
  ];
  // expandible table
  const expandedRowRender = () => {
    const columns = [
      {
        title: "Feature Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Feature Description",
        dataIndex: "description",
        key: "description",
      },
    ];
    const data = [];
    // nested loop to get features
    subscriptionPlan?.length > 0 &&
      subscriptionPlan?.forEach((subscription) => {
        subscription?.features?.en?.forEach((feature) => {
          data.push({
            title: feature?.title,
            description: feature?.description,
          });
        });
      });
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
 
  return (
    <React.Fragment>
       <Helmet>
        <title>4EVERINLOVE - SUBSCRIPTIONS</title>
        <meta name="description" content="4everinlove Subscriptions" />
      </Helmet>
      <div className="header">
        <h3>All Subscriptions</h3>
      </div>
      <Table
        columns={columns}
        pagination={false}
        loading={loader}
        scroll={{ x: 1023 }}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        dataSource={subscriptionPlan}
      />
    </React.Fragment>
  );
};
export default SubscriptionPlan;
