import React from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Checkbox, Input, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Love from "../../assets/login/4-transparent.png";
import axios from "axios";
import cookie from "react-cookies";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onChange = (newName) => {
    cookie.save("token", newName, { path: "/" });
  };

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  // onFinish
  const onFinish = (values) => {
    setLoader(true);
    console.log(values);
    const payload = {
      email: values.email,
      password: values.password,
    };
    axios
      .post(`${process.env.REACT_APP_FRONTED_URL}/login?locale=en`, payload)
      .then((response) => {
        onChange(
          response?.data?.data?.token &&
            JSON.stringify(response?.data?.data?.token)
        );
        setLoader(false);
        openNotification("Success", "Your are loggedIn Successfully");
        navigate("/");
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
        openNotification("Error", "Incorrect email and password");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <React.Fragment>
       <Helmet>
        <title>4EVERINLOVE - LOGIN</title>
        <meta name="description" content="4everinlove Login Page" />
      </Helmet>
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img src={Love} alt="" />
          <h1>EVERINLOVE</h1>
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className="welcome_head">WELCOME TO 4EVERINLOVE</p>

          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input placeholder="Email" style={{ fontSize: "16px" }} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              style={{ fontSize: "16px" }}
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="login-form-button">
              {loader ? <Spin /> : "LOGIN"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    </React.Fragment>
  );
};

export default Login;
