import React, { useState } from "react";
import Logo from "../../assets/login/4-transparent.png";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ContainerOutlined,
  UserOutlined,
  TeamOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  DollarOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  FileTextOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import MenuButton from "../dropdown/Menu";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const { Header, Sider, Content } = Layout;

const Layout1 = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const params = useParams();
  console.log("params", params);
  // token for color
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // function to get Tabs Props
  function getItem(label, key, icon, children, theme) {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  }

  // handler to show active part
  const activeTabHandler = (acitveRoute) => {
    switch (acitveRoute) {
      case "/":
        return "1";
      case "/admins":
        return "2";
      case "/users":
        return "3";
      case `/user-detail/${location?.pathname?.split("/")[2]}`:
        return "3";
      case "/users-requests":
        return "4";
      case "/subscribed-users":
        return "5";
      case "/subscriptions":
        return "6";
      case "/payments":
        return "7";
      case "/events":
        return "9";
      case `/edit-event/${location?.pathname?.split("/")[2]}`:
        return "9";
      case "/add-event":
        return "9";
      case "/event-tickets":
        return "10";
      case "/faq":
        return "11";
      case "/add-faq":
        return "11";
      case "/information":
        return "12";
      case "/edit-information":
        return "12";
      case "/calendar":
        return "13";
      case "/contact":
        return "14";
      case `/edit-contact-status/${location?.pathname?.split("/")[2]}`:
        return "14";
      case "/reports":
        return "15";

      default:
        return "1";
    }
  };

  // handler to show active sub menu options
  const activeSubOptionHandler = (acitveRoute) => {
    switch (acitveRoute) {
      case "/users":
        return "sub4";
      case `/user-detail/${location?.pathname?.split("/")[2]}`:
        return "sub4";
      case "/users-requests":
        return "sub4";
      case "/subscribed-users":
        return "sub1";
      case "/subscriptions":
        return "sub1";
      case "/events":
        return "sub2";
      case "/calendar":
        return "sub2";
      case "/event-tickets":
        return "sub2";
      case `/edit-event/${location?.pathname?.split("/")[2]}`:
        return "sub2";
      case "/add-event":
        return "sub2";
      default:
        return "";
    }
  };

  // sidenav items
  const items = [
    getItem(<Link to="/">Dashboard</Link>, "1", <AppstoreOutlined />),
    getItem(<Link to="/admins">Admin</Link>, "2", <UserOutlined />),
    getItem(<Link>Users</Link>, "sub4", <TeamOutlined />, [
      getItem(<Link to="/users?page=1">Users</Link>, "3"),
      getItem(<Link to="/users-requests?page=1">Requested Users</Link>, "4"),
    ]),
    getItem(<Link>Subscription</Link>, "sub1", <ProfileOutlined />, [
      getItem(<Link to="/subscribed-users">Subscribed Users</Link>, "5"),
      getItem(<Link to="/subscriptions">Subscription Plans</Link>, "6"),
    ]),
    getItem(<Link to="/payments">Payments</Link>, "7", <DollarOutlined />),

    getItem(<Link>Events</Link>, "sub2", <FileProtectOutlined />, [
      getItem(<Link to="/events?page=1">All Events</Link>, "9"),
      getItem(<Link to="/event-tickets">Event Tickets</Link>, "10"),
      getItem(<Link to="/calendar">Calendar</Link>, "13"),
      // ,
    ]),
    getItem(<Link to="/faq">FAQ</Link>, "11", <QuestionCircleOutlined />),
    getItem(
      <Link to="/information">Information</Link>,
      "12",
      <ContainerOutlined />
    ),
    getItem(<Link to="/contact">Contact Us</Link>, "14", <FileTextOutlined />),
    getItem(
      <Link to="/reports">Reports</Link>,
      "15",
      <ReconciliationOutlined />
    ),
  ];
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <div>
          {!collapsed ? (
            <Link to="/">
              <div className="sidebar_logo">
                <img src={Logo} alt="" />
                <h6>EVERINLOVE</h6>
              </div>
            </Link>
          ) : (
            <Link to="/">
              {" "}
              <div className="sidebar_logo">
                <img src={Logo} alt="" />
              </div>
            </Link>
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[activeTabHandler(location?.pathname)]}
          defaultOpenKeys={[activeSubOptionHandler(location?.pathname)]}
          items={items}
          selectedKeys={[activeTabHandler(location?.pathname)]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "20px",
              width: 64,
              height: 64,
            }}
          />
          <MenuButton />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflowY: "scroll",
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Layout1;
