import React from "react";
import { Helmet } from "react-helmet";
import { Table, Tabs, Space, Pagination } from "antd";
import { useState, useEffect, useRef } from "react";
import cookie from "react-cookies";
import axios from "axios";
// scroll Top
const useScroll = () => {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return [executeScroll, myRef];
};
const SubscriptionUser = () => {
  const [subscripUser, setSubscripUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [executeScroll, myRef] = useScroll();
  const [loader, setLoader] = useState(true);
  //method for API call
  const getSubscripUserHandler = (page) => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/subscription-plans/${page}?locale=en`,
        config
      )
      .then((response) => {
        setSubscripUser(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };
  //  API for subscription User
  useEffect(() => {
    getSubscripUserHandler(2);
  }, []);
  const onChange = (key) => {
    console.log(key);
    getSubscripUserHandler(key);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "",
      render: (data) => (
        <Space size="middle">{data?.first_name + " " + data?.last_name}</Space>
      ),
    },
    {
      title: "Category",
      dataIndex: "Premium",
      key: "Premium",
      render: () => <Space size="middle">Premium</Space>,
    },
  ];
  const items = [
    {
      key: "1",
      label: `Bronze Subscription Users`,
      children: (
        <Table
          columns={columns}
          dataSource={subscripUser?.data}
          pagination={false}
          loading={loader}
          scroll={{ x: 1023 }}
        />
      ),
    },
  ];
  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - SUBSCRIBED USERS</title>
        <meta name="description" content="4everinlove Subscribed Users" />
      </Helmet>
      <div ref={myRef}>
        <div className="header">
          <h3>All Subscribed Users</h3>
        </div>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <Pagination
          defaultPageSize={currentPage}
          current={currentPage}
          pageSize={
            subscripUser !== undefined && subscripUser?.length !== 0
              ? subscripUser?.per_page
              : 1
          }
          total={subscripUser?.total}
          onChange={(e) => {
            setCurrentPage(e);
            getSubscripUserHandler(e);
            executeScroll();
          }}
          className="pagination"
        />
      </div>
    </React.Fragment>
  );
};
export default SubscriptionUser;
