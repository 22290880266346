import React from "react";
import { useState, useEffect } from "react";
import { Col, Row, Card, Image, Spin, Empty } from "antd";
import axios from "axios";
import cookie from "react-cookies";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [dashboard, setDashboard] = useState([]);
  const [loader, setLoader] = useState(true);
  const { Meta } = Card;

  // handler to get data for dashboard
  const getDashboardHandler = () => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/admin-dashboard?locale=en`,
        config
      )
      .then((response) => {
        setDashboard(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  //  API for Faq
  useEffect(() => {
    getDashboardHandler();
  }, []);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - DASHBOARD</title>
        <meta name="description" content="4everinlove Dashboard" />
      </Helmet>
      <div className="header">
        <h3>Dashboard</h3>
      </div>
      <div>
        <Row gutter={24} className="dashboard_card">
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <div onClick={() => navigate("/users")} className="cursor-pointer">
              <Card
                title="Total Users"
                bordered={false}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  textAlign: "center",
                  margin: "15px 0px",
                }}
              >
                {loader ? (
                  <div className="spin_information">
                    <Spin />
                  </div>
                ) : (
                  <p>{dashboard.total_users}</p>
                )}
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <div
              onClick={() => navigate("/payments")}
              className="cursor-pointer"
            >
              <Card
                title="Total Payments"
                bordered={false}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  textAlign: "center",
                  margin: "15px 0px",
                }}
              >
                {loader ? (
                  <div className="spin_information">
                    <Spin />
                  </div>
                ) : (
                  <p>
                    <span style={{ paddingRight: "5px" }}>AED</span>
                    {dashboard.total_payments}
                  </p>
                )}
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <div
              onClick={() => navigate("/contact")}
              className="cursor-pointer"
            >
              <Card
                title="Contact Requests"
                bordered={false}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  textAlign: "center",
                  margin: "15px 0px",
                }}
              >
                {loader ? (
                  <div className="spin_information">
                    <Spin />
                  </div>
                ) : (
                  <p>{dashboard.contact_requests}</p>
                )}
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <div
              onClick={() => navigate("/users-requests")}
              className="cursor-pointer"
            >
              <Card
                title="KYC Verification"
                bordered={false}
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                  textAlign: "center",
                  margin: "15px 0px",
                }}
              >
                {loader ? (
                  <div className="spin_information">
                    <Spin />
                  </div>
                ) : (
                  <p>{dashboard.kyc_verification}</p>
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      <div className="head_dashboard">
        <h3>Latest Events</h3>
        <div>
          {loader ? (
            <div>
              <Spin className="spin-center-normal" />
            </div>
          ) : dashboard?.latest_events?.length > 0 ? (
            <div>
              <Row gutter={24} scroll={{ x: 1023 }}>
                {dashboard?.latest_events?.map((item, id) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} key={id}>
                    <Card
                      hoverable
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        margin: "15px 0px",
                      }}

                      cover={
                        <Image
                          height={320}
                          src={item?.cover_photo}
                          alt="example"
                          className="card-image"
                        />
                      }
                    >
                      <Meta
                        title={<h4 className="card-name">{item?.name?.en}</h4>}
                        description={
                          <p className="card-description">
                            {item?.description?.en}
                          </p>
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div className="empty-box-padding">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      </div>
      <div className="head_dashboard2">
        <h3>Latest Users</h3>
        <div>
          {loader ? (
            <div>
              <Spin className="spin-center-normal" />
            </div>
          ) : dashboard?.latest_users?.length > 0 ? (
            <div>
              <Row gutter={24} scroll={{ x: 1023 }}>
                {dashboard?.latest_users?.map((item, id) => (
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={4} key={id}>
                    <Card
                      hoverable
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        margin: "15px 0px",
                        
                      }}
                      className="card-img-cover"
                      cover={
                        <Image
                          height={200}
                          src={item?.profile_photo}
                          alt=""
                          className="card-image"
                        />
                      }
                    >
                      <Meta
                        className="dashboard-card-profile"
                        avatar={
                          <Image
                            src={item?.profile_photo}
                            alt=""
                            width={50}
                            height={50}
                            style={{ borderRadius: "50%" }}
                          />
                        }
                        title={
                          <h4 className="dashboard-card-profile card-name">
                            {item?.first_name + " " + item?.last_name}
                          </h4>
                        }
                        description={
                          <p className=" card-description">{item?.gender}</p>
                        }
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div className="empty-box-padding">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
