import React from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Input, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import cookie from "react-cookies";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";

const FaqForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [information, setInformation] = useState({});
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [policy, setPolicy] = useState("");
  const [validation, setValidation] = useState({
    terms: false,
    policy: false,
  });

  // useEffect to get content
  useEffect(() => {
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(
        `${process.env.REACT_APP_FRONTED_URL}/website-information?locale=en`,
        config
      )
      .then((response) => {
        setInformation(response?.data?.data);
        setTermsAndConditions(
          response?.data?.data?.pages?.terms_and_conditions?.en
        );
        setPolicy(response?.data?.data?.pages?.privacy_policy?.en);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, []);

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };
  let validationDuplicate = { ...validation };

  const onFinish = (values) => {
    // error
    if (
      termsAndConditions === "<p><br></p>" ||
      policy === "<p><br></p>" ||
      termsAndConditions === "" ||
      policy === ""
    ) {
      if (termsAndConditions === "<p><br></p>" || termsAndConditions === "") {
        validationDuplicate.terms = true;
      }
      if (policy === "<p><br></p>" || policy === "") {
        validationDuplicate.policy = true;
      }
      setValidation(validationDuplicate);
    }
    // success
    else {
      setLoader2(true);
      const token = cookie.load("token");
      const payload = {
        links: {
          facebook: values?.facebook,
          twitter: values?.twitter,
          linkedin: values?.linkedin,
          instagram: values?.instagram,
        },
        pages: {
          terms_and_conditions: { en: termsAndConditions },
          privacy_policy: { en: policy },
        },
        address: values?.address,
        phone: values?.phone,
      };
      const config = {
        headers: { Authorization: `Bearer ${JSON.parse(token)}` },
      };
      axios
        .post(
          `${process.env.REACT_APP_FRONTED_URL}/website-information?locale=en&locale=en`,
          payload,
          config
        )
        .then(() => {
          setLoader(false);
          navigate("/information");
          openNotification("Success", "Updated Successfully");
        })
        .catch((error) => {
          setLoader(false);
          openNotification("Error", "");
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - EDIT INFORMATION</title>
        <meta name="description" content="4everinlove Edit Information" />
      </Helmet>
      <div className="header">
        <h3>Edit Information</h3>
      </div>
      {!loader ? (
        <div className="form_container">
          <Form
            layout="vertical"
            initialValues={{
              // links
              facebook: information?.links?.facebook,
              twitter: information?.links?.twitter,
              linkedin: information?.links?.linkedin,
              instagram: information?.links?.instagram,
              // contact
              address: information?.address,
              phone: information?.phone,
              // pages
              terms_conditions: information?.pages?.terms_and_conditions?.en,
              privacy_policy: information?.pages?.privacy_policy?.en,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="py-5">
              <h4>LINKS</h4>
            </div>
            {/* Links */}
            <Form.Item
              name="facebook"
              label="Facebook"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="twitter"
              label="Twitter"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="linkedin"
              label="LinkedIn"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="instagram"
              label="Instagram"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            <div className="py-5">
              <h4>CONTACT</h4>
            </div>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
            {/* terms and conditions */}
            <div className="py-5">
              <h4>PAGES</h4>
            </div>
            {/* terms and conditions */}
            <Form.Item
              name="terms_conditions"
              label="Terms & Conditions"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <ReactQuill
                theme="snow"
                value={termsAndConditions}
                onChange={(e) => {
                  setTermsAndConditions(e);
                  if (e === "" || e === "<p><br></p>") {
                    validationDuplicate.terms = true;
                    setValidation(validationDuplicate);
                  } else {
                    validationDuplicate.terms = false;
                    setValidation(validationDuplicate);
                  }
                }}
                name="terms_conditions"
                className={
                  validation?.terms &&
                  "ant-input ant-input-status-error css-dev-only-do-not-override-12jzuas border-radius-2"
                }
                style={{ padding: "0px" }}
              />
              <div class={validation?.terms && "ant-form-item-explain-error"}>
                {validation?.terms && "This field is required"}
              </div>
            </Form.Item>

            {/* policy */}
            <Form.Item
              name="privacy_policy"
              label="Privacy Policy"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <ReactQuill
                theme="snow"
                value={policy}
                onChange={(e) => {
                  setPolicy(e);
                  if (e === "" || e === "<p><br></p>") {
                    validationDuplicate.policy = true;
                    setValidation(validationDuplicate);
                  } else {
                    validationDuplicate.policy = false;
                    setValidation(validationDuplicate);
                  }
                }}
                name="privacy_policy"
                className={
                  validation?.policy &&
                  "ant-input ant-input-status-error css-dev-only-do-not-override-12jzuas border-radius-2"
                }
                style={{ padding: "0px" }}
              />
              <div class={validation?.policy && "ant-form-item-explain-error"}>
                {validation?.policy && "This field is required"}
              </div>
            </Form.Item>
            {/* button */}
            <Form.Item>
              <div className="form_submit">
                <Button
                  className="back_button"
                  onClick={() => navigate("/information")}
                >
                  Back
                </Button>
                <Button htmlType="submit">
                  {" "}
                  {loader2 ? "wait..." : "Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="spin_information">
          <Spin />
        </div>
      )}
    </React.Fragment>
  );
};
export default FaqForm;
