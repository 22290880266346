import React from "react";
import { Helmet } from "react-helmet";
import { Card, Skeleton, Popconfirm, message, Spin } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { EditOutlined, DeleteFilled } from "@ant-design/icons";
import EditFAQModal from "./editFaq";
import cookie from "react-cookies";

const Faq = () => {
  const [allFaq, setAllFaq] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(false);
  // modal states
  const [editData, setEditData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //method for API call
  const getFaqHandler = () => {
    setLoader(true);
    // login token
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    //axios call
    axios
      .get(`${process.env.REACT_APP_FRONTED_URL}/faqs?page=1&locale=en`, config)
      .then((response) => {
        setAllFaq(response?.data?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  //  API for Faq
  useEffect(() => {
    getFaqHandler();
  }, []);

  // confirm handler
  const confirm = (data) => {
    setSelectedRecord(data?.id);
    const token = cookie.load("token");
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(token)}` },
    };
    // API call to delete selected record
    axios
      .delete(
        `${process.env.REACT_APP_FRONTED_URL}/faqs?locale=en&faqs_ids[0]=${data?.id}`,
        config
      )
      .then(() => {
        // to delete locally from our database
        const remaingRecords = allFaq?.filter((item) => item?.id !== data?.id);
        setAllFaq(remaingRecords);
        // success message
        message.success("Record deleted Successfully");
        setSelectedRecord(false);
      })
      .catch(() => {
        message.error("Error");
        setSelectedRecord(false);
      });
  };

  const cancel = (e) => {
    console.log(e);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>4EVERINLOVE - FAQs</title>
        <meta name="description" content="4everinlove FAQs" />
      </Helmet>
      <div className="header">
        <h3>FAQs</h3>
        <Link to="/add-faq">
          <button className="cursor-pointer">Add FAQ</button>
        </Link>
      </div>
      <div>
        {loader ? (
          <div className="faq_content">
            {[1, 2, 3, 4, 5].map((index) => (
              <div className="py-4" key={index}>
                <Card
                  type="inner"
                  title={<Skeleton.Input active size="small" />}
                >
                  <Skeleton.Input active size="small" block />
                </Card>
              </div>
            ))}
          </div>
        ) : (
          <div className="faq_content">
            {allFaq?.map((data, index) => (
              <div className="py-4" key={index}>
                <Card
                  type="inner"
                  title={data?.question?.en}
                  extra={
                    <div className="flex">
                      <div className="px-4">
                        <EditOutlined
                          className="cursor-pointer"
                          onClick={() => {
                            showModal();
                            setEditData({ ...data, selectedIndex: index });
                          }}
                        />
                      </div>
                      <div className="px-4">
                        {data?.id === selectedRecord ? (
                          <Spin />
                        ) : (
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => confirm(data)}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteFilled className="cursor-pointer" />
                          </Popconfirm>
                        )}
                      </div>
                    </div>
                  }
                >
                  {data?.answer?.en}
                </Card>
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <EditFAQModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          showModal={showModal}
          handleCancel={handleCancel}
          handleOk={handleOk}
          editData={editData}
          setAllFaq={setAllFaq}
          allFaq={allFaq}
        />
      </div>
    </React.Fragment>
  );
};
export default Faq;
